import React from "react";
import { graphql, PageProps } from "gatsby";

import SEO from "@shared/seo";
import { Query, SanityNewsConnection, SanitySlug } from "@graphql-types";
import { useStore } from "@util/states";
import { Hero } from "@global";
import Sections from "@shared/sections";
import Layout from "@shared/layout";

interface Data extends Query {
  altRegions: SanityNewsConnection;
}

interface Props extends PageProps {
  data: Data;
  pageContext: {
    allArticles: SanityNewsConnection;
    slug: SanitySlug;
  };
}

export default function articleTemplate({ data, pageContext }: Props) {
  if (data.sanityNews == null) return null;

  const { currentRegion } = useStore();

  const { sanityNews, altRegions } = data;

  const { hero, sections } = sanityNews;

  const altRegionsOrganised = altRegions.nodes.map(alt => alt.region);

  const allRegionArticles = pageContext.allArticles.nodes.filter(article => {
    if (article.region == null) return null;
    return article.region.iban === currentRegion.iban;
  });

  return (
    <>
      <SEO
        seoData={sanityNews?.seo}
        slug={sanityNews?.slug?.current as string}
        alternateRegions={altRegionsOrganised}
        currentIban={sanityNews?.region?.iban}
      />
      <Layout>
        <Hero data={hero} articleData={allRegionArticles} slug={pageContext.slug} />
        {sections && sections.map(section => <Sections key={section?._key} data={section} />)}
      </Layout>
    </>
  );
}

export const query = graphql`
  query article($slug: String, $iban: String) {
    sanityNews(slug: { current: { eq: $slug } }, region: { iban: { eq: $iban } }) {
      seo {
        ...sanitySeo
      }
      title
      region {
        iban
      }
      slug {
        current
      }
      hero {
        ...sanityHero
      }
      sections {
        ...sanitySections
      }
    }
    altRegions: allSanityNews(filter: { slug: { current: { eq: $slug } } }) {
      nodes {
        region {
          ...sanityRegion
        }
      }
    }
  }
`;
